module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"DevLab.Career","short_name":"DevLab","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"standalone","icon":"static/design/favicon/favicon-32x32.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ddfea57596dc9ac7e391d3ce34eacc05"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-W6G99V6","defaultDataLayer":{"type":"function","value":"function () {\n          return {\n            js: new Date(),\n          };\n        }"},"dataLayerName":"dataLayer","includeInDevelopment":false,"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../node_modules/gatsby-plugin-fontawesome-css/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
